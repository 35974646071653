<template>
  <CModal
      @update:show="closeModal"
      :title="`${eventTypes[eventType]} template`"
      color="primary"
      :show="showModal"
      centered>
    <CInput label="Name" placeholder="Enter your name" v-model="label.name" />
    <CTextarea
        label="Header text"
        placeholder="Enter your header text"
        v-model="label.header_text"
    />
    <CTextarea
        label="Bottom text"
        placeholder="Enter your bottom text"
        v-model="label.bottom_text"
    />
  </CModal>
</template>

<script lang="js">
import {CModal,CInput,CTextarea} from "@coreui/vue/src";

export default {
  name:"LiteLabelModal",
  props:['showModal','eventType','label'],
  components:{
    CModal,
    CInput,
    CTextarea
  },
  data(){
    return{
      eventTypes: {
        create: "Create",
        edit: "Edit",
      },
    }
  },
  methods:{
    closeModal(show, event, accept) {
      if (accept) {
        this.$emit("accept");
      } else {
        this.$emit("close");
      }
    },
  }
}
</script>

<style scoped lang="scss"></style>
